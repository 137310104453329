import fetchBrands from "./fetchBrands";
import fetchDevices from "./fetchDevices";
import fetchEstablishments from "./fetchEstablishments";
import fetchPacks from "./fetchPacks";
import fetchResidences from "./fetchResidences";
import fetchScreens from "./fetchScreens";
import fetchUser from "./fetchUser";
import fetchUsers from "./fetchUsers";

export default async function refreshData(user) {
  await fetchUser(user);
  fetchDevices();
  fetchScreens();
  fetchBrands();
  fetchEstablishments();
  fetchResidences();
  fetchUsers();
  fetchPacks();
}
