import { configureStore } from "@reduxjs/toolkit";
import isMobileReducer from "./reducers/isMobile";
import themeModeReducer from "./reducers/themeMode";
import pageTitleReducer from "./reducers/pageTitle";
import snackbarReducer from "./reducers/snackbar";
import devicesReducer from "./reducers/devices";
import screensReducer from "./reducers/screens";
import brandsReducer from "./reducers/brands";
import establishmentsReducer from "./reducers/establishments";
import residencesReducer from "./reducers/residences";
import pageActionsReducer from "./reducers/pageActions";
import userDBReducer from "./reducers/userDB";
import usersReducer from "./reducers/users";
import selectedEstablishmentReducer from "./reducers/selectedEstablishment";
import deleteDialogReducer from "./reducers/deleteDialog";
import packsReducer from "./reducers/packs";

export const store = configureStore({
  reducer: {
    brands: brandsReducer,
    deleteDialog: deleteDialogReducer,
    devices: devicesReducer,
    establishments: establishmentsReducer,
    isMobile: isMobileReducer,
    packs: packsReducer,
    pageActions: pageActionsReducer,
    pageTitle: pageTitleReducer,
    residences: residencesReducer,
    screens: screensReducer,
    selectedEstablishment: selectedEstablishmentReducer,
    snackbar: snackbarReducer,
    themeMode: themeModeReducer,
    userDB: userDBReducer,
    users: usersReducer,
  },
});
