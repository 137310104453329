const initialState = { open: false, type: "", records: [] };

function deleteDialogReducer(state = initialState, action) {
  switch (action.type) {
    case "deleteDialog/update":
      return action.payload;
    default:
      return state;
  }
}

export default deleteDialogReducer;
