import { useSelector } from "react-redux";
import DataTableBase from "./DataTableBase";
import { useTranslation } from "react-i18next";
import React from "react";
import { useNavigate } from "react-router-dom";
import { store } from "../../store";

export default function PacksTable() {
  const packs = useSelector((state) => state.packs);
  const devices = useSelector((state) => state.devices);

  const navigate = useNavigate();

  const { t } = useTranslation("common");

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "water_deveui",
      label: t("common.water_meter"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          let device = devices.find((device) => device.dev_eui === value);
          return device ? device.name : "N/A";
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            console.log(order, obj1, obj2);
            let val1 = obj1.data;
            let val2 = obj2.data;

            //find the devices
            let deviceA = devices.find((device) => device.dev_eui === val1);
            let deviceB = devices.find((device) => device.dev_eui === val2);

            //compare the names
            return (
              deviceA.name.localeCompare(deviceB.name) *
              (order === "asc" ? 1 : -1)
            );
          };
        },
      },
    },
    {
      name: "elec_deveui",
      label: t("common.electricity_meter"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          let device = devices.find((device) => device.dev_eui === value);
          return device ? device.name : "N/A";
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            console.log(order, obj1, obj2);
            let val1 = obj1.data;
            let val2 = obj2.data;

            //find the devices
            let deviceA = devices.find((device) => device.dev_eui === val1);
            let deviceB = devices.find((device) => device.dev_eui === val2);

            //compare the names
            return (
              deviceA.name.localeCompare(deviceB.name) *
              (order === "asc" ? 1 : -1)
            );
          };
        },
      },
    },
    {
      name: "screen_deveui",
      label: t("common.screen"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          let device = devices.find((device) => device.dev_eui === value);
          return device ? device.name : "N/A";
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            console.log(order, obj1, obj2);
            let val1 = obj1.data;
            let val2 = obj2.data;

            //find the devices
            let deviceA = devices.find((device) => device.dev_eui === val1);
            let deviceB = devices.find((device) => device.dev_eui === val2);

            //compare the names
            return (
              deviceA.name.localeCompare(deviceB.name) *
              (order === "asc" ? 1 : -1)
            );
          };
        },
      },
    },
  ];

  const [selectedPacks, setSelectedpacks] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const tableActions = [
    {
      name: t("common.delete_selected", {
        name: t("common.packs"),
        count: selectedPacks.length,
      }),
      icon: "Delete",
      action: () => {
        store.dispatch({
          type: "deleteDialog/update",
          payload: {
            open: true,
            type: "packs",
            records: selectedPacks,
          },
        });
      },
    },
  ];

  return (
    <DataTableBase
      columns={columns}
      data={packs}
      title={t("common.packs")}
      id="packs-table"
      name="packs"
      pageSize={10}
      selectedItems={selectedPacks}
      setSelectedItems={setSelectedpacks}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      selectableRows="multiple"
      actions={tableActions}
      sortOrder="dev_eui"
      onRowClick={(rowData) => {
        navigate(`/packs/${rowData[0]}`);
      }}
    />
  );
}
