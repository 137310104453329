const initialState = [];

function packsReducer(state = initialState, action) {
  switch (action.type) {
    case "packs/update":
      return action.payload;
    case "packs/delete":
      return state.filter((pack) => !action.payload.includes(pack.id));
    default:
      return state;
  }
}

export default packsReducer;
