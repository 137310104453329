import fetchData from "../API/Fetch";
import moment from "moment";

export default async function fetchAvgDeviceHistory(
  id,
  field,
  startDate,
  endDate,
  groupedBy
) {
  let start_timestamp = 0;
  let end_timestamp = 0;

  if (!startDate || !endDate) {
    startDate = moment().startOf("day").format("YYYY-MM-DD");
    endDate = moment().endOf("day").format("YYYY-MM-DD");
  }

  start_timestamp = moment(startDate).unix();
  end_timestamp = moment(endDate).unix();

  let result = await fetchData(
    `/deviceHistory/${id}/history/${field}/${groupedBy}/${start_timestamp}/${end_timestamp}`
  );

  return result;
}
