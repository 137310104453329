import { withAuthenticationRequired } from "@auth0/auth0-react";
import { store } from "../store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import {
  CloudUpload,
  GasMeter,
  House,
  Inventory,
  Monitor,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import importData from "../functions/import";

function Import(props) {
  const { t } = useTranslation("common");

  const [establishmentId, setEstablishmentId] = useState(null);
  const [fileName, setFileName] = useState("No file selected");
  const [file, setFile] = useState(null);

  const [deviceType, setDeviceType] = useState(null);
  const [importing, setImporting] = useState(false);

  const navigate = useNavigate();

  const establishments = useSelector((state) => state.establishments);
  const themeMode = useSelector((state) => state.themeMode);
  const [results, setResults] = useState(null);

  const { type } = props;

  useEffect(() => {
    let title = t("common.importer");

    if (type) {
      title = t("common.import_label", { what: t(`common.${type}`) });
    }

    store.dispatch({
      type: "pageTitle/update",
      payload: title,
    });

    let pageActions = [];

    store.dispatch({ type: "pageActions/update", payload: pageActions });
  }, [type, t]);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <>
      {type && (
        <Grid item xs={12} sx={{ mb: 2 }}>
          {type === "screens" && (
            <Typography
              variant="body2"
              color="textPrimary"
              onClick={() => {
                window.open("/sample_screens.csv", "_blank");
              }}
              sx={{
                // on hover, change cursor to pointer
                "&:hover": {
                  cursor: "pointer",
                  textDecoration: "underline",
                },
              }}
            >
              {t("common.import_sample", { what: t("common.screens") })}
            </Typography>
          )}
          {type === "meters" && (
            <Typography
              variant="body2"
              color="textPrimary"
              sx={{
                // on hover, change cursor to pointer
                "&:hover": {
                  cursor: "pointer",
                  textDecoration: "underline",
                },
              }}
              onClick={() => {
                window.open("/sample_meters.csv", "_blank");
              }}
            >
              {t("common.import_sample", { what: t("common.meters") })}
            </Typography>
          )}
          {type === "packs" && (
            <Typography
              variant="body2"
              color="textPrimary"
              sx={{
                // on hover, change cursor to pointer
                "&:hover": {
                  cursor: "pointer",
                  textDecoration: "underline",
                },
              }}
              onClick={() => {
                window.open("/sample_packs.csv", "_blank");
              }}
            >
              {t("common.import_sample", { what: t("common.packs") })}
            </Typography>
          )}
          {type === "residences" && (
            <Typography
              variant="body2"
              color="textPrimary"
              sx={{
                // on hover, change cursor to pointer
                "&:hover": {
                  cursor: "pointer",
                  textDecoration: "underline",
                },
              }}
              onClick={() => {
                window.open("/sample_residences.csv", "_blank");
              }}
            >
              {t("common.import_sample", { what: t("common.residences") })}
            </Typography>
          )}
        </Grid>
      )}
      {!type && (
        <Grid item xs={4}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12} md={7}>
              <Typography variant="h6" color="textPrimary">
                {t("common.import_label", { what: t("common.screens") })}
              </Typography>
              <Button
                variant="contained"
                color="success"
                fullWidth
                startIcon={<Monitor />}
                sx={{ py: 2, my: 2 }}
                onClick={() => {
                  navigate("/import/screens");
                }}
              >
                {t("common.import_label", { what: t("common.screens") })}
              </Button>
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography variant="h6" color="textPrimary">
                {t("common.import_label", { what: t("common.meters") })}
              </Typography>
              <Button
                variant="contained"
                color="success"
                fullWidth
                startIcon={<GasMeter />}
                sx={{ py: 2, my: 2 }}
                onClick={() => {
                  navigate("/import/meters");
                }}
              >
                {t("common.import_label", { what: t("common.meters") })}
              </Button>
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography variant="h6" color="textPrimary">
                {t("common.import_label", { what: t("common.packs") })}
              </Typography>
              <Button
                variant="contained"
                color="success"
                fullWidth
                startIcon={<Inventory />}
                sx={{ py: 2, my: 2 }}
                onClick={() => {
                  navigate("/import/packs");
                }}
              >
                {t("common.import_label", { what: t("common.packs") })}
              </Button>
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography variant="h6" color="textPrimary">
                {t("common.import_label", { what: t("common.residences") })}
              </Typography>
              <Button
                variant="contained"
                color="success"
                fullWidth
                startIcon={<House />}
                sx={{ py: 2, my: 2 }}
                onClick={() => {
                  navigate("/import/residences");
                }}
              >
                {t("common.import_label", { what: t("common.residences") })}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      {type && type === "residences" && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              select
              label={t("common.establishment")}
              fullWidth
              sx={{ mb: 2 }}
              value={establishmentId ? establishmentId : ""}
              onChange={(event) => setEstablishmentId(event.target.value)}
            >
              {establishments.map((establishment) => (
                <MenuItem key={establishment.id} value={establishment.id}>
                  {establishment.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
      )}
      {type && type === "meters" && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              select
              label={t("common.device_type")}
              fullWidth
              sx={{ mb: 2 }}
              value={deviceType ? deviceType : ""}
              onChange={(event) => setDeviceType(event.target.value)}
            >
              <MenuItem key={"device_type_id"} value={2}>
                {t("common.milesight_ct101")}
              </MenuItem>
              <MenuItem key={"device_type_id"} value={3}>
                {t("common.quandify_cubic_meter")}
              </MenuItem>
              <MenuItem key={"device_type_id"} value={4}>
                {t("common.eastronMid")}
              </MenuItem>
            </TextField>
          </FormControl>
        </Grid>
      )}
      {type && (
        <>
          <Grid item xs={12}>
            <FormControl>
              <Stack spacing={2} direction="row" justifyContent="center">
                <Button
                  component="label"
                  variant="contained"
                  color="success"
                  startIcon={<CloudUpload />}
                >
                  {t("common.upload")}
                  <VisuallyHiddenInput
                    type="file"
                    accept=".csv"
                    onChange={(event) => {
                      setFileName(event.target.files[0].name);
                      setFile(event.target.files[0]);
                    }}
                    multiple
                  />
                </Button>
                <Typography
                  variant="caption"
                  sx={{ mt: 1 }}
                  color={
                    themeMode === "dark" ? "text.secondary" : "text.primary"
                  }
                >
                  {fileName}
                </Typography>
              </Stack>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  importData(
                    setResults,
                    setImporting,
                    file,
                    type,
                    establishmentId,
                    deviceType,
                    t
                  )
                }
                disabled={importing}
              >
                {t("common.import")}
              </Button>

              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  navigate("/import");
                }}
              >
                {t("common.cancel")}
              </Button>
            </Stack>
          </Grid>
        </>
      )}
      {importing && (
        <Grid item xs={12}>
          <Stack spacing={2} direction="row" justifyContent="center">
            <CircularProgress />
            <Typography variant="h6" sx={{ mt: 4 }} color="textPrimary">
              {t("common.importing")}
            </Typography>
          </Stack>
        </Grid>
      )}
      {results && results.total && (
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mt: 4 }} color="textPrimary">
            {t("common.import_results")}
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }} color="textPrimary">
            {results.message}
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }} color="textPrimary">
            {t("common.total")}: {results.total}
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }} color="textPrimary">
            {t("common.success")}: {results.success_count}
          </Typography>

          <Typography variant="body1" sx={{ mt: 2 }} color="textPrimary">
            {t("common.failed")}: {results.failed.length}
          </Typography>

          {
            //failed is an array with the row and error, loop through and display
          }
          {results.failed.length > 0 &&
            results.failed.map((error, index) => (
              <Stack spacing={2} direction="row" justifyContent="flex-start">
                {Object.keys(error).map((key) => (
                  <Typography
                    key={index}
                    variant="body1"
                    sx={{ mt: 2 }}
                    color="textPrimary"
                  >
                    {key}: {error[key]}
                  </Typography>
                ))}
              </Stack>
            ))}
        </Grid>
      )}
    </>
  );
}

export default withAuthenticationRequired(Import);
