import { withAuthenticationRequired } from "@auth0/auth0-react";
import { store } from "../store";
import { useTranslation } from "react-i18next";
import { Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { FormControl } from "@mui/material";
import { MenuItem } from "@mui/material";
import handleThemeChange from "../functions/handleThemeChange";
import { CONFIG } from "../config";
import i18next from "i18next";

function Settings(props) {
  const { t } = useTranslation("common");

  const [themeMode, setThemeMode] = useState(
    localStorage.getItem("theme") || CONFIG.defaultTheme
  );
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || CONFIG.defaultLanguage
  );

  const [refreshRate, setRefreshRate] = useState(
    localStorage.getItem("refreshRate") || CONFIG.defaultRefreshRate
  );

  const updateRefreshRate = (rate) => {
    localStorage.setItem("refreshRate", rate);
    setRefreshRate(rate);
  };

  const changeLanguage = (lng) => {
    localStorage.setItem("language", lng);

    //update the language
    i18next.changeLanguage(lng);
    setLanguage(lng);
  };

  useEffect(() => {
    store.dispatch({ type: "pageTitle/update", payload: t("common.settings") });
    store.dispatch({ type: "pageActions/update", payload: [] });
  }, [t]);

  //settings page, language, theme, update rate (seconds)

  return (
    <>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <TextField
            id="outlined-basic"
            label={t("common.language")}
            variant="outlined"
            select
            onChange={(e) => {
              changeLanguage(e.target.value);
            }}
            value={language}
          >
            <MenuItem value={"en"}>{t("common.english")}</MenuItem>
            <MenuItem value={"fr"}>{t("common.french")}</MenuItem>
          </TextField>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <TextField
            id="outlined-basic"
            label={t("common.theme")}
            variant="outlined"
            select
            value={themeMode}
            onChange={(e) => {
              setThemeMode(e.target.value);
              handleThemeChange(e.target.value);
            }}
          >
            <MenuItem value={"light"}>{t("common.light")}</MenuItem>
            <MenuItem value={"dark"}>{t("common.dark")}</MenuItem>
          </TextField>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <TextField
            id="outlined-basic"
            label={t("common.refreshRate")}
            variant="outlined"
            select
            value={refreshRate}
            onChange={(e) => {
              updateRefreshRate(e.target.value);
            }}
          >
            <MenuItem value={15}>15 {t("common.seconds")}</MenuItem>
            <MenuItem value={30}>30 {t("common.seconds")}</MenuItem>
            <MenuItem value={60}>1 {t("common.minute")}</MenuItem>
            <MenuItem value={120}>2 {t("common.minutes")}</MenuItem>
            <MenuItem value={300}>5 {t("common.minutes")}</MenuItem>
            <MenuItem value={600}>10 {t("common.minutes")}</MenuItem>
            <MenuItem value={900}>15 {t("common.minutes")}</MenuItem>
            <MenuItem value={1800}>30 {t("common.minutes")}</MenuItem>
            <MenuItem value={3600}>1 {t("common.hour")}</MenuItem>
          </TextField>
        </FormControl>
      </Grid>
    </>
  );
}

export default withAuthenticationRequired(Settings);
