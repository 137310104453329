import { FormControl, Grid, MenuItem, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { store } from "../store";
import { useTranslation } from "react-i18next";

export default function SelectEstablishment() {
  const establishments = useSelector((state) => state.establishments);
  const selectedEstablishment = useSelector(
    (state) => state.selectedEstablishment
  );

  const { t } = useTranslation("common");

  const handleChange = (event) => {
    store.dispatch({
      type: "selectedEstablishment/update",
      payload: event.target.value,
    });
    localStorage.setItem("selectedEstablishment", event.target.value);
  };

  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <TextField
          select
          label={t("common.establishment")}
          onChange={handleChange}
          value={selectedEstablishment}
        >
          <MenuItem key="0" value="0">
            {t("common.all")}
          </MenuItem>
          {establishments.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </Grid>
  );
}
