import fetchData from "../API/Fetch";
import { store } from "../store";

export default async function fetchPacks() {
  let result = await fetchData(`/packs/`);

  store.dispatch({
    type: "packs/update",
    payload: result ? result : [],
  });
}
