import { CONFIG } from "../config";
import { updateThemeMode } from "./updateThemeMode";

export default async function handleThemeChange(mode = null) {
  if (mode !== null) {
    updateThemeMode(mode);
    return;
  }

  let theme = localStorage.getItem("theme");

  if (theme === null) {
    updateThemeMode(CONFIG.defaultTheme);
  } else if (theme === "light") {
    updateThemeMode("dark");
  } else {
    updateThemeMode("light");
  }
}
