import { PunchClock } from "@mui/icons-material";
import { Box, Card, Stack, Typography } from "@mui/material";
import moment from "moment";

export default function LastCheckin(props) {
  const { title, value } = props;

  const formatDate = (date) => {
    //if date = "N/A" return "N/A"
    if (date === "N/A") {
      return date;
    }
    return moment(date).format("HH:mm DD/MM/YYYY");
  };

  return (
    <Card sx={{ display: "flex", flexFlow: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 1,
        }}
      >
        <Box>
          <Typography>{title}</Typography>
        </Box>
      </Box>
      <Stack
        spacing={2}
        direction={"row"}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          p: 2,
          pt: 3,
          //full height of the card
          flex: "1 1 auto",
        }}
      >
        <PunchClock sx={{ fontSize: 80 }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Typography
            sx={{
              alignSelf: "center",
              fontSize: "2em",
              mr: 1,
              color: "#bdbdbd",
            }}
          >
            {formatDate(value)}
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
}
