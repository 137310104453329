import { useSelector } from "react-redux";
import DataTableBase from "./DataTableBase";
import { useTranslation } from "react-i18next";
import React from "react";
import { useNavigate } from "react-router-dom";
import { store } from "../../store";

export default function DevicesTable() {
  const devices = useSelector((state) => state.devices);

  const navigate = useNavigate();

  const [selectedDevices, setSelecteddevices] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const { t } = useTranslation("common");

  const columns = [
    {
      name: "dev_eui",
      label: "Dev EUI",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: t("common.name"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "device_type",
      label: t("common.device_type"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value ? value.name : "N/A";
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = obj1.data;
            let val2 = obj2.data;

            return (
              val1.name.localeCompare(val2.name) * (order === "asc" ? 1 : -1)
            );
          };
        },
      },
    },
    {
      name: "fcnt",
      label: t("common.fcnt"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "time_updated",
      label: t("common.last_seen"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return new Date(value).toLocaleString();
        },
      },
    },
  ];

  const tableActions = [
    {
      name: t("common.delete_selected", {
        name: t("common.devices"),
        count: selectedDevices.length,
      }),
      icon: "Delete",
      action: () => {
        store.dispatch({
          type: "deleteDialog/update",
          payload: {
            open: true,
            type: "devices",
            records: selectedDevices,
          },
        });
      },
    },
  ];

  return (
    <DataTableBase
      columns={columns}
      data={devices}
      title={t("common.devices")}
      id="device-list"
      name="device-list"
      pageSize={10}
      selectedItems={selectedDevices}
      setSelectedItems={setSelecteddevices}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      selectableRows="multiple"
      actions={tableActions}
      sortOrder="dev_eui"
      onRowClick={(rowData) => {
        navigate(`/devices/${rowData[0]}`);
      }}
      useDevEui
    />
  );
}
