const initialState = [];

function establishmentsReducer(state = initialState, action) {
  switch (action.type) {
    case "establishments/update":
      return action.payload;
    case "establishments/delete":
      return state.filter(
        (establishment) => !action.payload.includes(establishment.id)
      );
    default:
      return state;
  }
}

export default establishmentsReducer;
