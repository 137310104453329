import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import SelectEstablishment from "../selectEstablishment";
import { useNavigate } from "react-router-dom";

export default function PageActions(actions) {
  const pageActions = useSelector((state) => state.pageActions);

  const navigate = useNavigate();

  //for each action return a button in a grid, next to each other
  return pageActions.map((action) =>
    action.object ? (
      <Grid
        item
        key={action.name}
        //flex items end
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {action.object}
      </Grid>
    ) : action.name === "SelectEstablishment" ? (
      <Grid item key={action.name}>
        <SelectEstablishment />
      </Grid>
    ) : (
      <Grid
        item
        key={action.name}
        sx={{
          //align right
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          color="success"
          startIcon={<Add />}
          onClick={() => {
            navigate(action.url);
          }}
        >
          {action.name}
        </Button>
      </Grid>
    )
  );
}
