import { useSelector } from "react-redux";
import DataTableBase from "./DataTableBase";
import { useTranslation } from "react-i18next";
import React from "react";

export default function ScreensTable() {
  const screens = useSelector((state) => state.screens);
  const residences = useSelector((state) => state.residences);

  const { t } = useTranslation("common");

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "residence_id",
      label: t("common.residence"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          let residence = residences.find(
            (residence) => parseInt(residence.id) === parseInt(value)
          );

          return residence ? residence.name : "";
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = obj1.data;
            let val2 = obj2.data;

            let residenceA = residences.find(
              (residence) => parseInt(residence.id) === parseInt(val1)
            );
            let residenceB = residences.find(
              (residence) => parseInt(residence.id) === parseInt(val2)
            );

            return (
              residenceA.name.localeCompare(residenceB.name) *
              (order === "asc" ? 1 : -1)
            );
          };
        },
      },
    },
    {
      name: "dev_eui",
      label: "Dev EUI",
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "screen_on",
      label: t("common.screen_on"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value ? t("common.yes") : t("common.no");
        },
      },
    },
    {
      name: "smiley_color",
      label: t("common.smiley_color"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "water_liter",
      label: t("common.water_liter"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "electricity_kwh",
      label: t("common.electricity_kwh"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "water_color",
      label: t("common.water_color"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "electricity_color",
      label: t("common.electricity_color"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "uplink_rate_seconds",
      label: t("common.uplink_rate_seconds"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "screen_brightness",
      label: t("common.screen_brightness"),
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const tableActions = [];

  return (
    <DataTableBase
      columns={columns}
      data={screens}
      title={t("common.screens")}
      id="screen-list"
      name="screen-list"
      pageSize={10}
      actions={tableActions}
      sortOrder="residence_id"
      onRowClick={() => {
        console.log("Row clicked");
      }}
    />
  );
}
