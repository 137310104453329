import fetchData from "../API/Fetch";
import { store } from "../store";

export default async function fetchEstablishments() {
  let result = await fetchData(`/establishments/`);

  store.dispatch({
    type: "establishments/update",
    payload: result ? result : [],
  });

  store.dispatch({
    type: "selectedEstablishment/update",
    payload: localStorage.getItem("selectedEstablishment") || result[0].id,
  });
}
