import { withAuthenticationRequired } from "@auth0/auth0-react";
import { MenuItem, TextField } from "@mui/material";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { store } from "../store";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { FormControl } from "@mui/material";
import { postData } from "../API/Post";
import updateSnackbarMessage from "../functions/updateSnackbarMessage";
import { Button } from "@mui/material";
import { Stack } from "@mui/material";
import { putData } from "../API/Put";
import fetchPacks from "../functions/fetchPacks";
import { useEffect, useState } from "react";
import fetchData from "../API/Fetch";

function PackUpdate() {
  const devices = useSelector((state) => state.devices);

  const navigate = useNavigate();

  const params = useParams();
  const { t } = useTranslation("common");

  const [pack, setPack] = useState(null);

  useEffect(() => {
    if (!params.id) {
      return;
    }

    fetchData(`/packs/${params.id}`).then((data) => {
      setPack(data);
    });

    store.dispatch({
      type: "pageActions/update",
      payload: [],
    });

    const pageTitle = params.id
      ? `${t("common.update")} ${t("common.pack")}`
      : t("common.add_new", { name: t("common.pack") });
    store.dispatch({
      type: "pageTitle/update",
      payload: pageTitle,
    });
  }, [params.id, t]);

  const { register, handleSubmit, control, reset } = useForm({
    defaultValues: {
      id: "",
      water_deveui: 0,
      elec_deveui: 0,
      screen_deveui: 0,
    },
  });

  useEffect(() => {
    if (!pack) {
      return;
    }

    const { id, water_deveui, elec_deveui, screen_deveui } = pack;

    reset({
      id: id,
      water_deveui: water_deveui,
      elec_deveui: elec_deveui,
      screen_deveui: screen_deveui,
    });
  }, [pack, reset]);

  const validationLabels = {
    id: {
      required: t("common.required", { name: t("common.id") }),
    },
    water_deveui: {
      required: t("common.required", { name: t("common.water_meter") }),
    },
    elec_deveui: {
      required: t("common.required", { name: t("common.electricity_meter") }),
    },
  };

  const onSubmit = async (data) => {
    let { id, water_deveui, elec_deveui, screen_deveui } = data;

    let jsonData = {
      id: id,
      water_deveui: water_deveui,
      elec_deveui: elec_deveui,
      screen_deveui: screen_deveui,
    };

    //if screen_deveui is 0, remove it from the json data, same for water and elec
    if (screen_deveui === 1) {
      jsonData.screen_deveui = null;
    }

    if (water_deveui === 1) {
      jsonData.water_deveui = null;
    }

    if (elec_deveui === 1) {
      jsonData.elec_deveui = null;
    }

    let response = null;

    if (pack !== null) {
      response = await putData(`/packs/${pack.id}`, jsonData);
    } else {
      response = await postData(`/packs/`, jsonData);
    }

    if (response && response.status === 200) {
      updateSnackbarMessage(
        pack
          ? t("common.updated_success", { name: t("common.pack") })
          : t("common.added_success", { name: t("common.pack") })
      );

      fetchPacks();
      navigate("/packs/");
    } else {
      updateSnackbarMessage(
        pack
          ? t("common.update_error", { name: t("common.pack") })
          : t("common.add_error", { name: t("common.pack") })
      );
    }
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} direction={"row"}>
        <Grid item xs={12} md={7}>
          <Controller
            name="id"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="id"
                  label={t("common.id")}
                  fullWidth
                  {...field}
                  error={error !== undefined}
                  helperText={error ? validationLabels.id[error.type] : ""}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  {...register("id")}
                />
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} md={7}>
          <Controller
            name="screen_deveui"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="screen_deveui"
                  label={t("common.screen")}
                  fullWidth
                  {...field}
                  error={error !== undefined}
                  helperText={
                    error ? validationLabels.screen_deveui[error.type] : ""
                  }
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  {...register("screen_deveui")}
                  select
                >
                  <MenuItem key="0-screen" value={0}>
                    N/A
                  </MenuItem>
                  {devices
                    .filter((device) => device.device_type_id === 1)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((device) => (
                      <MenuItem key={device.id} value={device.dev_eui}>
                        {device.name}
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} md={7}>
          <Controller
            name="water_deveui"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="water_deveui"
                  label={t("common.water_meter")}
                  fullWidth
                  {...field}
                  error={error !== undefined}
                  helperText={
                    error ? validationLabels.water_deveui[error.type] : ""
                  }
                  select
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  {...register("water_deveui")}
                >
                  <MenuItem key="0-water" value={0}>
                    N/A
                  </MenuItem>
                  {devices
                    .filter((device) => device.device_type_id === 3)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((device) => (
                      <MenuItem key={device.id} value={device.dev_eui}>
                        {device.name}
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} md={7}>
          <Controller
            name="elec_deveui"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth variant="outlined">
                <TextField
                  select
                  id="elec_deveui"
                  label={t("common.electricity_meter")}
                  fullWidth
                  {...field}
                  error={error !== undefined}
                  helperText={
                    error ? validationLabels.elec_deveui[error.type] : ""
                  }
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  {...register("elec_deveui")}
                >
                  <MenuItem key="0-elec" value={0}>
                    N/A
                  </MenuItem>
                  {devices
                    .filter(
                      (device) =>
                        device.device_type_id === 2 ||
                        device.device_type_id === 4
                    )
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((device) => (
                      <MenuItem key={device.id} value={device.dev_eui}>
                        {device.name}
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={handleSubmit(onSubmit)}
            >
              {pack && pack.id ? t("common.update") : t("common.add")}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withAuthenticationRequired(PackUpdate);
