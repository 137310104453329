import { useTheme } from "@emotion/react";
import { Card, CardContent, CardHeader } from "@mui/material";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Rectangle,
  Bar,
  BarChart,
} from "recharts";

export default function DeviceGraph(props) {
  const { title, value, data, aspect, groupedBy } = props;

  let valueName = value;

  const theme = useTheme();

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <ResponsiveContainer aspect={aspect} width="100%">
          <BarChart
            data={data}
            margin={{ top: 0, right: 30, left: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="time_created"
              type={"category"} //format date to be more readable
              tickFormatter={(time) => {
                if (groupedBy === "daily") {
                  //remove time from date
                  return new Date(time).toLocaleDateString();
                } else if (groupedBy === "hourly") {
                  return new Date(time).toLocaleTimeString();
                } else {
                  return new Date(time).toLocaleString();
                }
              }}
            />
            <YAxis />
            <Tooltip
              cursor={false}
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              formatter={(value, name, props) => {
                //round value to 2 decimal places
                value = Math.round(value * 100) / 100;
                return [value, valueName];
              }}
              tickFormatter={(time) => {
                if (groupedBy === "daily") {
                  return new Date(time).toLocaleDateString();
                } else if (groupedBy === "hourly") {
                  return new Date(time).toLocaleTimeString();
                } else {
                  return new Date(time).toLocaleString();
                }
              }}
            />
            <Bar
              dataKey={value}
              fill="#bdbdbd"
              isAnimationActive={false}
              activeBar={<Rectangle fill="#6bc769" />}
            />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
