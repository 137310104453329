import updateSnackbarMessage from "./updateSnackbarMessage";
import { postData } from "../API/Post";

export default async function importData(
  setResults,
  setImporting,
  file,
  type,
  establishmentId,
  deviceType,
  t
) {
  setResults(null);
  setImporting(true);

  if (!establishmentId && type === "residences") {
    updateSnackbarMessage(t("common.no_establishment_selected"));
    return;
  }

  if (!deviceType && type === "meters") {
    updateSnackbarMessage(t("common.no_device_type_selected"));
    return;
  }

  let result = null;

  const formData = new FormData();
  formData.append("file", file);

  if (type === "meters") {
    formData.append("device_type_id", deviceType);
  } else if (type === "residences") {
    formData.append("establishment_id", establishmentId);
  }

  try {
    result = await postData("/import/" + type, formData);
    setResults(result.data ? result.data : result);
  } catch (error) {
    updateSnackbarMessage(t("common.import_failed"));
  } finally {
    setImporting(false);
  }
}
