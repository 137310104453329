const initialState = [];

function brandsReducer(state = initialState, action) {
  switch (action.type) {
    case "brands/update":
      return action.payload;
    case "brands/delete":
      return state.filter((brand) => !action.payload.includes(brand.id));
    default:
      return state;
  }
}

export default brandsReducer;
